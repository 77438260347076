<template>
    <v-container class="pa-0 ma-0">
        <Datatable

                :api-endpoint="'datatables/settings/roomData/'"
                :datatable-headers="datatableHeaders"
                no-excel-export
                show-select-checkbox


                @deleteEntries="deleteEntries"
                @deleteEntry="deleteEntry"
                @displayEntry="displayEntry"
                @editEntry="editEntry"

                :permission-delete="true"
                :permission-edit="true"

                ref="roomPlanerDataTable"

                show-delete-buttons
                show-display-buttons
                show-edit-buttons
        >
        </Datatable>
    </v-container>
</template>

<script>

    import Datatable from "../../../datatable/Datatable";
    import {Events} from "../../../../plugins/events";

    export default {
        name: "RoomsComponent",
        components:{
            Datatable,
        },
        data(){
            return{
                id:null,
                datatableHeaders:[
                    {
                        text:'ID',
                        value:'id',
                        align: 'left',
                        width: 80,
                    },
                    {
                        text: this.$t('erp.lang_room'),
                        value:'room_name'
                    },
                    {
                        text: this.$t('erp.lang_roomWidth'),
                        value:'room_width'
                    },
                    {
                        text:this.$t('erp.lang_roomType'),
                        value:'roomPlanType',
                    },
                    {
                        text: this.$t('erp.lang_seatingTime'),
                        value:'roomTime'
                    },
                ],
            }
        },
        filters: {
            filterType(type) {
                if(type === 1) {
                    return "Abstract";
                }else if(type === 2) {
                    return "Architect";
                }
            }
        },
        methods:{
            deleteData(idsToDelete = []){
                let self = this;
                this.$swal({
                    title: this.$t('erp.lang_roomPlanerDeleteRoomSub'),
                    text: this.$t('erp.lang_roomPlanerDeleteRoom'),
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post("delete/settings/room/", {
                            roomIDs: idsToDelete,
                        }).then((res) => {
                            //console.log("response res")
                            if (res.status===200) {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('erp.lang_roomPlanerSuccesfullRoomDelete'),
                                    color: "success"
                                });
                                //console.log("response succe")
                                self.$refs.roomPlanerDataTable.getDataFromApi();
                                self.$refs.roomPlanerDataTable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                                //console.log("response failed")
                            }
                        }).catch(err=>{
                            //console.log(["response err",idsToDelete])
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                });
            },
            deleteEntry(entry){
                this.id=entry.id;
                this.deleteData();
            },
            deleteEntries(entries){
                this.deleteData(entries)
            },
            displayEntry(entry){
                this.id = entry.id;
                if(parseInt(entry.roomPlanType)===1){
                    this.$router.push({name:"settings.cashierSettings.roomFloor.abstract",params:{id:this.id}})
                }
                // Redirect to table map
            },
            editEntry(entry){
                this.id = entry.id;

                this.$router.push({name:"settings.cashierSettings.roomPlaner.edit",params:{id:this.id}})
            },
            getType(entry){
               return (parseInt(entry.roomPlanType)===1)?"Abstract":"Architect";
            }

        }
    }
</script>

<style scoped>

</style>
